var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import "phaser";
var InitialScene = /** @class */ (function (_super) {
    __extends(InitialScene, _super);
    function InitialScene() {
        var _this = _super.call(this, {
            key: "GameScene",
        }) || this;
        _this.score = 0;
        _this.laserTime = new Date();
        _this.enemySpawnTimer = new Date();
        return _this;
    }
    InitialScene.prototype.init = function (params) { };
    InitialScene.prototype.preload = function () {
        this.load.image("ship", "../game_assets/phaser_shooter/tiny_ship.png");
    };
    InitialScene.prototype.create = function () {
        this.player = this.physics.add
            .sprite(100, 450, "ship")
            .setCollideWorldBounds(true);
        this.cursors = this.input.keyboard.createCursorKeys();
        this.lasers = this.physics.add.group();
        this.enemyShips = this.physics.add.group();
        this.physics.add.collider(this.lasers, this.enemyShips, this.destroyEnemy, null, this);
        this.scoreText = this.add.text(16, 16, "score: 0", {
            fontSize: "32px",
            color: "#FFF",
        });
    };
    InitialScene.prototype.update = function () {
        if (this.cursors.down.isDown) {
            this.player.setVelocityY(160);
        }
        else if (this.cursors.up.isDown) {
            this.player.setVelocityY(-160);
        }
        else {
            this.player.setVelocityY(0);
        }
        if (this.cursors.left.isDown) {
            this.player.setVelocityX(-160);
        }
        else if (this.cursors.right.isDown) {
            this.player.setVelocityX(160);
        }
        else {
            this.player.setVelocityX(0);
        }
        if (this.cursors.space.isDown) {
            this.playerShootLaser();
        }
        this.spawnEnemy();
    };
    InitialScene.prototype.playerShootLaser = function () {
        if (this.laserTime.getTime() + 1000 < new Date().getTime()) {
            var laserRectangle = this.add.rectangle(this.player.x + 75, this.player.y + 5, 80, 5, 0xffffff);
            this.lasers.add(laserRectangle).setVelocityX(500);
            this.laserTime = new Date();
        }
    };
    InitialScene.prototype.spawnEnemy = function () {
        if (this.enemySpawnTimer.getTime() + 3000 < new Date().getTime()) {
            var y = Phaser.Math.Between(50, this.sys.canvas.height - 50);
            this.enemyShips
                .create(this.sys.canvas.width, y, "ship")
                .setVelocityX(-50)
                .setScale(-1, 1);
            this.enemySpawnTimer = new Date();
        }
    };
    InitialScene.prototype.destroyEnemy = function (laser, enemyShip) {
        // small hack because I'm not sure how to set each laser as immovable
        this.lasers.setVelocityX(500);
        enemyShip.destroy();
        this.score += 10;
        this.scoreText.setText("score: " + this.score);
    };
    return InitialScene;
}(Phaser.Scene));
var config = {
    title: "Starfall",
    width: 800,
    height: 600,
    parent: "game",
    scene: [InitialScene],
    physics: {
        default: "arcade",
        arcade: {
            debug: false,
        },
    },
};
var MyGame = /** @class */ (function (_super) {
    __extends(MyGame, _super);
    function MyGame(config) {
        return _super.call(this, config) || this;
    }
    return MyGame;
}(Phaser.Game));
export { MyGame };
export { config };
