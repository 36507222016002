import Component from "./component";
import React from "react";
import { createRoot } from "react-dom/client";
import { MyGame, config } from "./game";
import * as Sentry from "@sentry/browser";
var container = document.createElement("div");
document.body.appendChild(container);
var root = createRoot(container);
Sentry.init({
    dsn: "https://98c8ca6d6f0a873556ed7bdd3538d4eb@o4508306885771264.ingest.us.sentry.io/4508306936627200",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/api\.jbeat\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
});
root.render(React.createElement(Component, null));
window.onload = function () {
    var game = new MyGame(config);
};
