import React from 'react';
var Component = function () {
    var handleErroButtonPress = function () {
        throw new Error('This is a test error');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: handleErroButtonPress }, "Trigger Test Error"),
        React.createElement("div", null, "Wahoo"),
        React.createElement("div", { id: "game" })));
};
export default Component;
